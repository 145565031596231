export default {
    VIEW_ADMIN_DASHBOARD: 'view-admin-dashboard',
    VIEW_ALL_USERS: 'view-all-users',
    ADD_USER: 'add-user',
    UPDATE_USER: 'update-user',
    DELETE_USER: 'delete-user',
    GET_USER: 'get-user',
    UPDATE_USER_PROFILE: 'update-user-profile',
    VIEW_ALL_ROLES: 'view-all-roles',
    ADD_ROLE: 'add-role',
    UPDATE_ROLE: 'update-role',
    DELETE_ROLE: 'delete-role',
    GET_ROLE: 'get-role',
    VIEW_ALL_PERMISSIONS: 'view-all-permissions',
    ADD_PERMISSION: 'add-permission',
    UPDATE_PERMISSION: 'update-permission',
    DELETE_PERMISSION: 'delete-permission',
    GET_PERMISSION: 'get-permission',
    ASSIGN_PERMISSION: 'assign-permission',

    VIEW_BORE_EXPENSE_AMOUNT: 'view-all-bore-expense-amount',
    ADD_BORE_EXPENSE_AMOUNT: 'add-bore-expense-amount',
    UPDATE_BORE_EXPENSE_AMOUNT: 'update-bore-expense-amount',
    DELETE_BORE_EXPENSE_AMOUNT: 'delete-bore-expense-amount',
    GET_BORE_EXPENSE_AMOUNT: 'get-bore-expense-amount',
    VIEW_BORE_RECEIVED_AMOUNT: 'view-all-bore-recived-amount',
    ADD_BORE_RECEIVED_AMOUNT: 'add-bore-recived-amount',
    UPDATE_BORE_RECEIVED_AMOUNT: 'update-bore-recived-amount',
    DELETE_BORE_RECEIVED_AMOUNT: 'delete-bore-recived-amount',
    GET_BORE_RECEIVED_AMOUNT: 'get-bore-recived-amount',
    VIEW_BORE_BILL: 'view-all-bore-bill',
    ADD_BORE_BILL: 'add-bore-bill',
    UPDATE_BORE_BILL: 'update-bore-bill',
    DELETE_BORE_BILL: 'delete-bore-bill',
    GET_BORE_BILL: 'get-bore-bill',
    VIEW_AGENT_PERFORMANCE: 'view-agent-performance',
    DOWNLOAD_AGENT_PERFORMANCE: 'download-agent-performance',
    VIEW_BORE_REPORT: 'view-bore-report',
    DELETE_BORE_REPORT: 'delete-bore-report',
    DOWNLOAD_BORE_REPORT: 'download-bore-report',
    BORE_DEPTH_REPORT: 'bore-depth-report',
    DOWNLOAD_BORE_DEPTH_REPORT: 'download-bore-depth-report',
    VIEW_AGENT_REPORT: 'view-agent-report',
    DOWNLOAD_AGENT_REPORT: 'download-agent-report',
    VIEW_BORE_COMMISSION: 'view-bore-commission',
    ADD_BORE_COMMISSION: 'add-bore-commission', 
    VIEW_ALL_ENTRY: 'view-all-entry',
    DOWNLOAD_ALL_ENTRY: 'download-all-entry'
};
